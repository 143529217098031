import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/firestore';
import { registerLocaleData } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  size;
  blankchargenumber;
  laterwork2: boolean;



  constructor(
    private firestore: AngularFirestore) { }

form = new FormGroup({
  testdate: new FormControl(''),
  werkstoffName: new FormControl(''),
  shortName: new FormControl(''),
  blankName: new FormControl(''),
  cargeNumber: new FormControl(''),
  blankmaterial: new FormControl(''),
});
blankform = new FormGroup({
  blankName: new FormControl(''),
  cargeNumber: new FormControl(''),
  blankmaterial: new FormControl(''),
});
materialtypeform = new FormGroup({
  name: new FormControl(''),
  typekey: new FormControl(''),
});
materialbrandform = new FormGroup({
  brandname: new FormControl(''),
  typebrandkey: new FormControl(''),
});
materialcolorsform = new FormGroup({
  brand: new FormControl(''),
});
colorsform = new FormGroup({
  color: new FormControl(''),
});
commentform = new FormGroup({
  extracomment: new FormControl(''),
  deliverydate: new FormControl(''),
});
billingform = new FormGroup({
  billfinish: new FormControl(''),
});
workingform = new FormGroup({
  workingblank: new FormControl(''),
  workingcharge: new FormControl(''),
  workingblank2: new FormControl(''),
  workingcharge2: new FormControl(''),
  workingbill: new FormControl(''),
  workingshippingcost: new FormControl(''),
  workingtime: new FormControl(''),
  workingmachine: new FormControl(''),
  workingholder: new FormControl(''),
  workingtime2: new FormControl(''),
  workingmachine2: new FormControl(''),
  workingholder2: new FormControl(''),
  laterwork: new FormControl(''),
});
secondworkingform = new FormGroup({
  workingblank2: new FormControl(''),
  workingcharge2: new FormControl(''),
  workingtime2: new FormControl(''),
  workingmachine2: new FormControl(''),
  workingholder2: new FormControl(''),
});


createDentalmaterial(data) {
  return new Promise<any>((resolve, reject) => {
    this.firestore
      .collection('dentalmaterial')
      .add(data)
      .then(res => {}, err => reject(err));
  });
}
createRohlinge(data) {
  return new Promise<any>((resolve, reject) => {
    this.firestore
      .collection('rohlinge')
      .add(data)
      .then(res => {}, err => reject(err));
  });
}

createMaterialtype(data) {
  return new Promise<any>((resolve, reject) => {
    this.firestore
      .collection('materialtypes')
      .add(data)
      .then(res => {}, err => reject(err));
  });
}

createMaterialbrand(data) {
  return new Promise<any>((resolve, reject) => {
    this.firestore
      .collection('materialbrands')
      .add(data)
      .then(res => {}, err => reject(err));
  });
}

createMaterialcolor(data) {
  return new Promise<any>((resolve, reject) => {
    this.firestore
      .collection('materialcolors')
      .add(data)
      .then(res => {}, err => reject(err));
  });
}

createColor(data) {
  return new Promise<any>((resolve, reject) => {
    this.firestore
      .collection('dentalcolors')
      .add(data)
      .then(res => {}, err => reject(err));
  });
}

getRohlinge() {
  return this.firestore.collection('rohlinge')
  .snapshotChanges();
}

getChargenumber() {
  console.log(this.workingform.get('workingblank').value);
  return this.firestore.collection('rohlinge', ref => {
    return ref.where('blankName', '==', this.workingform.get('workingblank').value);
  }).snapshotChanges();
}

getChargenumber2() {
  return this.firestore.collection('rohlinge', ref => {
    return ref.where('blankName', '==', this.workingform.get('workingblank2').value);
  }).snapshotChanges();
}
getChargenumber3() {
  return this.firestore.collection('rohlinge', ref => {
    return ref.where('blankName', '==', this.secondworkingform.get('workingblank2').value);
  }).snapshotChanges();
}


getTitanRohlinge2() {
  this.firestore.collection('rohlinge');
}


getDentalmaterial() {
  return this.firestore.collection('materialtypes').snapshotChanges();
}

getDentalmaterialbrand() {
  return this.firestore.collection('materialbrands').snapshotChanges();
}

getDentalmaterialcolor() {
  return this.firestore.collection('materialcolors').snapshotChanges();
}

getDentalcolor() {
  return this.firestore.collection('dentalcolors', ref => {
    return ref.orderBy('color');
  }).snapshotChanges();
}

getUsers() {
  return this.firestore.collection('users').snapshotChanges();
}

getUploads() {
  return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 1);
    }).snapshotChanges();
}

getDTUploads() {
  return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 0).where('companyname', '==', 'Dental-Technik Hamm');
    }).snapshotChanges();
}

getallUploads() {
  return this.firestore.collection('files', ref => {
    return ref.orderBy('date');
  }).snapshotChanges();
}

getworkingUploads() {
  return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 2);
    }).snapshotChanges();
}

getworkingPrinting() {
  return this.firestore.collection('files', ref => {
      return ref.where('status', '>', 1).where('printing', '==', true);
    }).snapshotChanges();
}

getworkingUploads2() {
  return this.firestore.collection('files', ref => {
      return ref.where('laterwork', '==', true);
    }).snapshotChanges();
}

PrepareMillingUploads() {
  return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 3);
    }).snapshotChanges();
}

PrepareMillingUploads2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachineavaible2', '==', 1);
    }).snapshotChanges();
}

getbillingUploads() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingbill', '==', true).where('laterwork', '==', false);
    }).snapshotChanges();
}

getreadyUploads() {
  return this.firestore.collection('files', ref => {
      return ref.where('millingfinish', '==', true)
      .where('blankmillingfinish', '==', true);
    }).snapshotChanges();
}

getreadyUploads2() {
  return this.firestore.collection('files', ref => {
      return ref.where('millingfinish2', '==', true)
      .where('blankmillingfinish2', '==', true);
    }).snapshotChanges();
}

getMachineUploadsDTleftBlank() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DT').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsDTrightBlank() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DT').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsDTleftBlank2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DT').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsDTrightBlank2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DT').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
}
getMachineUploadsDTleft() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DT').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
}

getMachineUploadsDTright() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DT').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
}

getMachineUploadsDCTleft() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DCT').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
}

getMachineUploadsDCTright() {
  return this.firestore.collection('files', ref => {
    return ref.where('workingmachine', '==', 'DCT').where('blankmillingfinish', '==', false)
    .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0);
  }).snapshotChanges();
}


getMachineUploadsDTright2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DT').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
}

getMachineUploadsDCTleftBlank() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DCT').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsDCTrightBlank() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DCT').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsDCTleftBlank2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DCT').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsDCTrightBlank2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DCT').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsORGleft() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'Organical').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
}

getMachineUploadsORGright() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'Organical').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
}
getMachineUploadsORGleftBlank() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'Organical').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsORGrightBlank() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'Organical').where('blankmillingfinish', '==', false)
      .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsORGleftBlank2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'Organical').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsORGrightBlank2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'Organical').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
}

getMachineUploadsDTleft2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DT').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
}



getMachineUploadsDCTleft2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DCT').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
}

getMachineUploadsDCTright2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DCT').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
}

getMachineUploadsORGleft2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'Organical').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
}

getMachineUploadsORGright2() {
  return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'Organical').where('blankmillingfinish2', '==', false)
      .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
}


deleteRohlinge(data) {
  return this.firestore
    .collection('rohlinge')
    .doc(data.payload.doc.id)
    .delete();
}

deleteUsers(data) {
  return this.firestore
    .collection('users')
    .doc(data.payload.doc.id)
    .delete();
}
deletematerial(data) {
  return this.firestore
    .collection('dentalmaterial')
    .doc(data.payload.doc.id)
    .delete();
}
deleteUploads(data) {
  return this.firestore
    .collection('files')
    .doc(data.payload.doc.id)
    .delete();
}

deletebrands(data) {
  return this.firestore
    .collection('files')
    .doc(data.payload.doc.id)
    .delete();
}

deletecolors(data) {
  return this.firestore
    .collection('files')
    .doc(data.payload.doc.id)
    .delete();
}

deletetypes(data) {
  return this.firestore
    .collection('files')
    .doc(data.payload.doc.id)
    .delete();
}

updateOrder(data) {
  this.addComment(data);
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ archiv: false, status: 2, docID: data.payload.doc.id, docID2: data.payload.doc.id }, { merge: true});

}

setdocID(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ docID: data.payload.doc.id, docID2: data.payload.doc.id }, { merge: true});

}
setprintstate(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ printstatus: 1 }, { merge: true});

}
setprintstate2(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ printstatus: 2 }, { merge: true});

}
setprintstate3(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ printstatus: 3 }, { merge: true});

}
setprintstate4(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ printstatus: 4 }, { merge: true});

}
setprintstate5(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ printstatus: 5 }, { merge: true});

}
setprintstatefinal(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ printstatus: 6, printing: false, printready: true }, { merge: true});

}

setreadystate(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ blankmillingfinish: true, millingfinish: true }, { merge: true});

}
setreadystate2(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ blankmillingfinish2: true, millingfinish2: true }, { merge: true});

}

SetfinalState(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ status: 11 }, { merge: true});

}

SetfinalState2(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ blank1ready: true , millingfinish: false}, { merge: true});

}

SetfinalState3(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ blank2ready: true, millingfinish2: false }, { merge: true});

}




setLaterworkfalse(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ laterwork: false }, { merge: true});

}

prepareforMilling(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({status: 4, workingmachineavaible: 0, blankmillingfinish: false, millingfinish: false }, { merge: true});

}

prepareforMilling2(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ workingmachineavaible2: 0, blankmillingfinish2: false, millingfinish2: false }, { merge: true});

}

updatebillingOrder(data) {
  console.log(data);
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ workingbill: false, billready: true, }, { merge: true});

}

addComment(data) {
  const extracomment = this.commentform.get('extracomment').value;
  return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({ comment: extracomment }, { merge: true});


}

getDTready(data) {
  const notes = this.commentform.get('extracomment').value;
  const deliverydate =  this.commentform.get('deliverydate').value;
  const date = `${Date.now()}`;
  return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({ status: 2, docID: data.payload.doc.id, docID2: data.payload.doc.id, notes, deliverydate, date }, { merge: true});


}

getDTreadytray(data) {
  const notes = this.commentform.get('extracomment').value;
  const date = `${Date.now()}`;
  return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({ status: 11, docID: data.payload.doc.id, docID2: data.payload.doc.id, notes, date }, { merge: true});


}

updateworkingOrder() {
  if (this.workingform.get('laterwork').value === '' ) {
    this.laterwork2 = false;
   } else if (this.workingform.get('laterwork').value === false) {
    this.laterwork2 = false;
   } else {
    this.laterwork2 = true;
   }
}

updateworkingOrderfinal(data) {
  const workingblank = this.workingform.get('workingblank').value;
  const workingcharge = this.workingform.get('workingcharge').value;
  const workingshippingcost = this.workingform.get('workingshippingcost').value;
  const workingbill = this.workingform.get('workingbill').value;
  const workingtime = this.workingform.get('workingtime').value;
  const workingmachine = this.workingform.get('workingmachine').value;
  const workingholder = this.workingform.get('workingholder').value;
  const workingblank2 = this.workingform.get('workingblank2').value;
  const workingcharge2 = this.workingform.get('workingcharge2').value;
  const workingtime2 = this.workingform.get('workingtime2').value;
  const workingmachine2 = this.workingform.get('workingmachine2').value;
  const workingholder2 = this.workingform.get('workingholder2').value;
  const laterwork = this.laterwork2;
  const workingmachineavaible = 1  ;
  const workingmachineavaible2 = 0  ;

    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ status: 3, workingblank, workingcharge, workingbill, workingtime,
          workingmachine, workingholder, workingshippingcost, workingcharge2, workingblank2,
          workingtime2, workingmachine2, workingholder2, workingmachineavaible, workingmachineavaible2, laterwork }, { merge: true});

}

updateworkingOrder2(data) {
  const workingblank2 = this.secondworkingform.get('workingblank2').value;
  const workingcharge2 = this.secondworkingform.get('workingcharge2').value;
  const workingtime2 = this.secondworkingform.get('workingtime2').value;
  const workingmachine2 = this.secondworkingform.get('workingmachine2').value;
  const workingholder2 = this.secondworkingform.get('workingholder2').value;
  const laterwork = false;
  const workingmachineavaible = 1  ;
  const workingmachineavaible2 = 1  ;

    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ status: 3, workingcharge2, workingblank2,
          workingtime2, workingmachine2, workingholder2, workingmachineavaible, workingmachineavaible2, laterwork }, { merge: true});

}

updateworkingOrder3(data) {
  const workingblank = this.workingform.get('workingblank').value;
  const workingcharge = this.workingform.get('workingcharge').value;
  const workingshippingcost = this.workingform.get('workingshippingcost').value;
  const workingbill = this.workingform.get('workingbill').value;
  const workingtime = this.workingform.get('workingtime').value;
  const workingmachine = this.workingform.get('workingmachine').value;
  const workingholder = this.workingform.get('workingholder').value;
  const workingblank2 = this.workingform.get('workingblank2').value;
  const workingcharge2 = this.workingform.get('workingcharge2').value;
  const workingtime2 = this.workingform.get('workingtime2').value;
  const workingmachine2 = this.workingform.get('workingmachine2').value;
  const workingholder2 = this.workingform.get('workingholder2').value;
  const laterwork = false;
  const workingmachineavaible = 1  ;
  const workingmachineavaible2 = 1  ;

    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ status: 3, workingblank, workingcharge, workingbill, workingtime,
          workingmachine, workingholder, workingshippingcost, workingcharge2, workingblank2,
          workingtime2, workingmachine2, workingholder2, workingmachineavaible, workingmachineavaible2, laterwork }, { merge: true});

}
movetoNew(data) {
    return this.firestore
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ status: 1 }, { merge: true});

}

NewOrderCount() {
  return this.firestore.collection('files', ref => {
    return ref.where('status', '==', 1);
  }).get();
}

WorkOrderCount() {
  return this.firestore.collection('files', ref => {
    return ref.where('status', '==', 2);
  }).get();
}

WorkOrderCount2() {
  return this.firestore.collection('files', ref => {
    return ref.where('laterwork', '==', true);
  }).get();
}

PrepareMillingCount() {
  return this.firestore.collection('files', ref => {
    return ref.where('status', '==', 3);
  }).get();
}

PrepareMillingCount2() {
  return this.firestore.collection('files', ref => {
    return ref.where('workingmachineavaible2', '==', 1);
  }).get();
}

BillingCount() {
  return this.firestore.collection('files', ref => {
    return ref.where('workingbill', '==', true).where('laterwork', '==', false);
  }).get();
}



}
