import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    ) { }

  isOpen = false;
  isOpen2 = false;
  showInfo = true;
  files;
  files2;
  size;
  dentalmaterial;
  dentalrohling;
  dentalrohlingcharge;

  public options: string;

  ngOnInit() {
    this.getUploads();
    this.getUploads2();
    this.firebaseService.NewOrderCount();
  }

  deleteUploads = data => this.firebaseService.deleteUploads(data);

  getUploads = () =>
  this.firebaseService.getreadyUploads().subscribe(res => (this.files = res))

  getUploads2 = () =>
  this.firebaseService.getreadyUploads2().subscribe(res => (this.files2 = res))

  toggleDisplay() {
    this.showInfo = !this.showInfo;
  }



SubmitIf(data) {
  if (data.payload.doc.data().blankmillingfinish === true && data.payload.doc.data().blankmillingfinish2 === true) {
    console.log('blankmillingfinish und blankmillingfinish2 auf true');
  } else {
    console.log('das gegenteil');
  }
  }

SubmitIf2(data) {
  if (data.payload.doc.data().blankmillingfinish === true && data.payload.doc.data().blankmillingfinish2 === true) {
    console.log('2.blankmillingfinish und blankmillingfinish2 auf true');
  } else {
    console.log('2.das gegenteil');
  }
  }


  Submit1(data) {
    this.markCompleted2(data);
    this.markCompleted(data);
  }

  Submit2(data) {
    this.markCompleted3(data);
    this.markCompleted(data);
  }


 // TODO Nur auf neuen Status setzen, wenn wirklich beide Rohkling efertig sind

 // set to Status 11
  markCompleted = data =>
    this.firebaseService.SetfinalState(data)
// set blank1 ready
  markCompleted2 = data =>
    this.firebaseService.SetfinalState2(data)
// // set blank2 ready
  markCompleted3 = data =>
  this.firebaseService.SetfinalState3(data)


}


