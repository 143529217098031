
import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    ) { }

  isOpen = false;
  isOpen2 = false;
  isOpen3 = false;
  showInfo = true;
  files;
  size;
  dentalmaterial;
  dentalrohling;
  dentalrohlingcharge;
  ModalID;

  public options: string;

  ngOnInit() {
    this.getUploads();
    this.getTotalNumber();
    this.getDentalrohling();
    this.firebaseService.NewOrderCount();
  }

  deleteUploads = data => this.firebaseService.deleteUploads(data);

  getUploads = () =>
  this.firebaseService.getbillingUploads().subscribe(res => (this.files = res))

  getTotalNumber = () =>
  this.firebaseService.BillingCount().subscribe(snap => {
    this.size = snap.size;
    console.log(this.size);
  })

  getDentalmaterial() {
    this.firebaseService
    .getDentalmaterial()
    .subscribe(res => (this.dentalmaterial = res));
   }

   getDentalrohling() {
    this.firebaseService
    .getRohlinge()
    .subscribe(res => (this.dentalrohling = res));
   }

   getDentalrohlingcharge() {
    this.firebaseService
    .getChargenumber()
    .subscribe(res => (this.dentalrohlingcharge = res));
   }



  Submit(data) {
    this.markCompleted(data);
    this.isOpen = false;
    this.isOpen3 = false;
  }

  SubmitDelete(data) {
    this.deleteUploads(data);
    this.isOpen2 = false;
  }


  markCompleted = data =>
    this.firebaseService.updatebillingOrder(data)

    moveBack = data =>
    this.firebaseService.movetoNew(data)

    toggleDisplay() {
      this.showInfo = !this.showInfo;
    }

    toggleDisplay2() {
      this.isOpen = !this.isOpen;
      this.isOpen3 = !this.isOpen3;
    }
    getOptions() {
      this.getDentalrohlingcharge();
    }

    copyMessage(val: string) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    MatchDocID(data) {
      this.ModalID = data.payload.doc.id;
    }
    DisplayModal1(data) {
      this.MatchDocID(data);
      this.isOpen = true;
    }
    DisplayModal2(data) {
      this.MatchDocID(data);
      this.isOpen3 = true;
    }

}

