export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAXYyacRQ6LZ97tdnApSEsATuR10W1pKe4',
    authDomain: 'dentaltechnik-login.firebaseapp.com',
    databaseURL: 'https://dentaltechnik-login.firebaseio.com',
    projectId: 'dentaltechnik-login',
    storageBucket: 'dentaltechnik-login.appspot.com',
    messagingSenderId: '944334447994',
    appId: '1:944334447994:web:45095e2a0997352f'

  }
};
