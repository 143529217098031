import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';

@Component({
  selector: 'app-werkstoff-list',
  templateUrl: './werkstoff-list.component.html',
  styleUrls: ['./werkstoff-list.component.scss']
})
export class WerkstoffListComponent implements OnInit {

  constructor(private firebaseService: FirebaseService) { }


  dentalmaterial;


  ngOnInit() {
    this.getDentalmaterial();
  }

  deletematerial = data => this.firebaseService.deletematerial(data);


  getDentalmaterial() {
    this.firebaseService
    .getDentalmaterial()
    .subscribe(res => (this.dentalmaterial = res));
   }
}

