import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KundenListComponent } from './kunden/kunden-list/kunden-list.component';
import { AddblankComponent } from './rohlinge/addblank/addblank.component';
import { UploadListComponent } from './uploads/upload-list/upload-list.component';
import { AddwerkstoffComponent } from './werkstoff/addwerkstoff/addwerkstoff.component';
import { MaschinenHomeComponent } from './maschinen/maschinen-home/maschinen-home.component';
import { MaschinenMB2Component } from './maschinen/maschinen-mb2/maschinen-mb2.component';
import { MaschinenOrganicalComponent } from './maschinen/maschinen-organical/maschinen-organical.component';
import { MaschinenMB1Component } from './maschinen/maschinen-mb1/maschinen-mb1.component';
import { WorkingorderComponent } from './workingorder/workingorder.component';
import { PrintingComponent } from './printing/printing.component';
import { BillingComponent } from './billing/billing.component';
import { PremillingComponent } from './premilling/premilling.component';
import { ShippingComponent } from './shipping/shipping.component';
import { OverviewComponent } from './overview/overview.component';
import { MeschedeuploadComponent } from './meschedeupload/meschedeupload.component';
import { ScannedordersComponent } from './scannedorders/scannedorders.component';
import { TrayComponent } from './tray/tray.component';
import { OtherlabComponent } from './otherlab/otherlab.component';
import { ScansComponent } from './scans/scans.component';
import { ScanstodoComponent } from './scanstodo/scanstodo.component';

const routes: Routes = [
  {path: 'rohlinge' , component: AddblankComponent},
  {path: 'scans' , component: ScansComponent},
  {path: 'scanstodo' , component: ScanstodoComponent},
  {path: 'kunden' , component: KundenListComponent},
  {path: 'uploads' , component: UploadListComponent},
  {path: 'werkstoff' , component: AddwerkstoffComponent},
  {path: 'maschinenhome' , component: MaschinenHomeComponent},
  {path: 'organical' , component: MaschinenOrganicalComponent},
  {path: 'working' , component: WorkingorderComponent},
  {path: 'printing' , component: PrintingComponent},
  {path: 'rechnungen' , component: BillingComponent},
  {path: 'vorbereitung' , component: PremillingComponent},
  {path: 'versand' , component: ShippingComponent},
  {path: 'mb1' , component: MaschinenMB1Component},
  {path: 'mb2' , component: MaschinenMB2Component},
  {path: 'Ubersicht' , component: OverviewComponent},
  {path: 'newMeschede' , component: MeschedeuploadComponent},
  {path: 'orderMeschede' , component: ScannedordersComponent},
  {path: 'trayupload' , component: TrayComponent},
  {path: 'otherlab' , component: OtherlabComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
