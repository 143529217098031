import { Component, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

import { FirebaseService } from '../shared/firebase.service';

interface Materialandcolor {
  material: string;
}

@Component({
  selector: 'app-otherlab',
  templateUrl: './otherlab.component.html',
  styleUrls: ['./otherlab.component.scss']
})
export class OtherlabComponent implements OnInit {

  isOpen = false;

  formGroup: FormGroup;
  descFormControl: FormControl;
  einheitenFormControl: FormControl;
  materialFormControl: FormControl;
  farbeFormControl: FormControl;
  lieferdatumFormControl: FormControl;
  typeFormControl: FormControl;
  brandFormControl: FormControl;
  patnameFormControl: FormControl;
  patlastnameFormControl: FormControl;
  technicanFormControl: FormControl;
  printingFormControl: FormControl;
  companynameFormControl: FormControl;
  deliverydateFormControl: FormControl;

  public options: string;
  public options2: string;


  materialbrandcolorCol: AngularFirestoreCollection<Materialandcolor>;
  materialbrandcolor: Observable<Materialandcolor[]>;
  materialtypeCollection: AngularFirestoreCollection<Materialandcolor>;
  materiatype: Observable<Materialandcolor[]>;
  materialbrandCollection: AngularFirestoreCollection<Materialandcolor>;
  materialbrand: Observable<Materialandcolor[]>;
  technicansCollection: AngularFirestoreCollection<Materialandcolor>;
  technicans;
  negativdate2;

  constructor(
    private db: AngularFirestore,
    private formBuilder: FormBuilder,
  ) {
    this.descFormControl = new FormControl('', Validators.required);
    this.einheitenFormControl = new FormControl('', Validators.required);
    this.materialFormControl = new FormControl('', Validators.required);
    this.farbeFormControl = new FormControl('', Validators.required);
    this.typeFormControl = new FormControl('', Validators.required);
    this.brandFormControl = new FormControl('', Validators.required);
    this.patnameFormControl = new FormControl('', Validators.required);
    this.patlastnameFormControl = new FormControl('', Validators.required);
    this.technicanFormControl = new FormControl('', Validators.required);
    this.printingFormControl = new FormControl('', Validators.required);
    this.companynameFormControl = new FormControl('', Validators.required);
    this.deliverydateFormControl = new FormControl('', Validators.required);

    this.formGroup = this.formBuilder.group({
     desc: this.descFormControl,
     ordernumber: this.einheitenFormControl,
     materialorder: this.materialFormControl,
     teethcolor: this.farbeFormControl,
     materialtype: this.typeFormControl,
     brand: this.brandFormControl,
     patname: this.patnameFormControl,
     patlastname: this.patlastnameFormControl,
     technican: this.technicanFormControl,
     printing: this.printingFormControl,
     companyname: this.companynameFormControl,
     deliverydate: this.deliverydateFormControl,
  });

  }

  ngOnInit() {
    this.getMaterialtypes();
    this.getTechnicans();
    
  }
  getTechnicans() {
    this.technicans = this.db.collection('technicans', ref => {
    return ref.orderBy('tecnumber'); }).valueChanges();
   }

  getMaterialtypes() {
    this.materialtypeCollection = this.db.collection('materialtypes');
    this.materiatype = this.materialtypeCollection.valueChanges();
  }

  getMaterialbrands() {

    const materialtype = this.formGroup.get('materialtype').value;

    this.materialbrandCollection = this.db.collection('materialbrands', ref => {
      return ref.where('typebrandkey', '==', materialtype);
    });
    this.materialbrand = this.materialbrandCollection.valueChanges();
  }

  getMaterialcolors() {

    const materialbrand = this.formGroup.get('brand').value;

    this.materialbrandcolorCol = this.db.collection('materialcolors', ref => {
      return ref.where('brand', '==', materialbrand);
    });
    this.materialbrandcolor = this.materialbrandcolorCol.valueChanges();
  }

  getOptions() {
    this.getMaterialbrands();
  }
  getOptions2() {
    this.getMaterialcolors();
  }


  totaltime() {
    const ms = this.formGroup.get('deliverydate').value;   // your input string
    const a = ms.split('.'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    const day = 100 -(+a[0]);
    const month = 100 - (+a[1]);
    const year = 10000 - (+a[2]);
    const d = day.toString()
    const m = month.toString()
    const y = year.toString()
    this.negativdate2 = ( y + m + d)
    console.log(day);
    console.log(month);
    console.log(year);
    console.log(this.negativdate2);
  }

  startUpload() {
    this.totaltime();
      this.isOpen = true;
      
      const ordernumber = this.formGroup.get('ordernumber').value;
      const whattosend = 'Datensatz'
      const description = this.formGroup.get('desc').value;
      const teethcolor = this.formGroup.get('teethcolor').value;
      const status = 2;
      const archiv = false;
      const printstatus = 0;
      const printing = this.formGroup.get('printing').value;
      const companyname = this.formGroup.get('companyname').value;
      const materialbrand = this.formGroup.get('brand').value;
      const materialtype = this.formGroup.get('materialtype').value;
      const patname = this.formGroup.get('patname').value;
      const patlastname = this.formGroup.get('patlastname').value;
      const technican = this.formGroup.get('technican').value;
      const date = Date.now();
      const deliverydate = this.formGroup.get('deliverydate').value;

      

      const negativdate = this.negativdate2;
      console.log('Hallo', negativdate);

    this.db.collection('files').add( { companyname,
           whattosend, status, ordernumber, description, teethcolor,
           materialbrand, materialtype, patname, patlastname, technican, archiv, printstatus, printing, date, deliverydate, negativdate});
      }

}

