import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';



interface Rohlinge {
  blankmaterial: any;
  blankstatus: boolean;
  blankName: any;
  cargeNumber: number;
}


@Component({
  selector: 'app-blank-list',
  templateUrl: './blank-list.component.html',
  styleUrls: ['./blank-list.component.scss']
})
export class BlankListComponent implements OnInit {

  notesCollection: AngularFirestoreCollection<Rohlinge>;
  titan: Observable<Rohlinge[]>;

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore
    ) { }

  rohlinge;

  titanrohlinge;

  dentalmaterial;

  ngOnInit() {
    this.getRohlinge();
    this.getDentalmaterial();
  }

  deleteiconShow(data): boolean {
    const material: string = data.payload.doc.data().material;
    switch (material) {
      case 'PMMA opak':
      case 'PMMA rosa':
        return true;
    }
    return false;
  }

  deleteRohlinge = data => this.firebaseService.deleteRohlinge(data);

  getRohlinge() {
     this.firebaseService
     .getRohlinge()
     .subscribe(res => (this.rohlinge = res));
    }


    getDentalmaterial() {
      this.firebaseService
      .getDentalmaterial()
      .subscribe(res => (this.dentalmaterial = res));
     }
}



