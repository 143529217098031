import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData, LocationStrategy, HashLocationStrategy } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { FormsModule } from '@angular/forms'


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddblankComponent } from './rohlinge/addblank/addblank.component';
import { BlankListComponent } from './rohlinge/blank-list/blank-list.component';
import { FirebaseService } from './shared/firebase.service';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { MatButtonModule } from '@angular/material/button';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KundenListComponent } from './kunden/kunden-list/kunden-list.component';
import { UploadListComponent } from './uploads/upload-list/upload-list.component';
import { HeaderComponent } from './layout/header/header.component';
import { AddwerkstoffComponent } from './werkstoff/addwerkstoff/addwerkstoff.component';
import { WerkstoffListComponent } from './werkstoff/werkstoff-list/werkstoff-list.component';
import { MaschinenHomeComponent } from './maschinen/maschinen-home/maschinen-home.component';
import { MaschinenOrganicalComponent } from './maschinen/maschinen-organical/maschinen-organical.component';
import { MaschinenMB1Component } from './maschinen/maschinen-mb1/maschinen-mb1.component';
import { MaschinenMB2Component } from './maschinen/maschinen-mb2/maschinen-mb2.component';
import { WorkingorderComponent } from './workingorder/workingorder.component';
import { PrintingComponent } from './printing/printing.component';
import { BillingComponent } from './billing/billing.component';
import { PremillingComponent } from './premilling/premilling.component';
import { ShippingComponent } from './shipping/shipping.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { QRCodeModule } from 'angular2-qrcode';
import { OverviewComponent } from './overview/overview.component';
import { MeschedeuploadComponent } from './meschedeupload/meschedeupload.component';
import { ScannedordersComponent } from './scannedorders/scannedorders.component';
import { TrayComponent } from './tray/tray.component';
import { OtherlabComponent } from './otherlab/otherlab.component';
import { ScansComponent } from './scans/scans.component';
import { ScanstodoComponent } from './scanstodo/scanstodo.component';





@NgModule({
  declarations: [
    AppComponent,
    AddblankComponent,
    BlankListComponent,
    KundenListComponent,
    UploadListComponent,
    HeaderComponent,
    AddwerkstoffComponent,
    WerkstoffListComponent,
    MaschinenHomeComponent,
    MaschinenOrganicalComponent,
    MaschinenMB1Component,
    MaschinenMB2Component,
    WorkingorderComponent,
    PrintingComponent,
    BillingComponent,
    PremillingComponent,
    ShippingComponent,
    OverviewComponent,
    MeschedeuploadComponent,
    ScannedordersComponent,
    TrayComponent,
    OtherlabComponent,
    ScansComponent,
    ScanstodoComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    MatButtonModule,
    ClarityModule,
    BrowserAnimationsModule,
    AutocompleteLibModule,
    NgxBarcodeModule,
    QRCodeModule,
    FormsModule,
  ],
  providers: [FirebaseService, { provide: LOCALE_ID, useValue: 'de' }, { provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
