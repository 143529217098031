import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService
  ) { }

  size;
  worknumber;
  billingnumber;
  preparenumber;

  ngOnInit() {
    this.getDocNumber();
    this.getDocNumber2();
    this.getDocNumber3();
    this.getDocNumber4();
  }


  getDocNumber = () =>
  this.firebaseService.NewOrderCount().subscribe(snap => {
    this.size = snap.size;
    console.log(this.size);
  })

  getDocNumber2 = () =>
  this.firebaseService.WorkOrderCount().subscribe(snap => {
    this.worknumber = snap.size;
    console.log(this.worknumber);
  })

  getDocNumber3 = () =>
  this.firebaseService.BillingCount().subscribe(snap => {
    this.billingnumber = snap.size;
    console.log(this.billingnumber);
  })

  getDocNumber4 = () =>
  this.firebaseService.PrepareMillingCount().subscribe(snap => {
    this.preparenumber = snap.size;
    console.log(this.preparenumber);
  })
}

