import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';



@Component({
  selector: 'app-addblank',
  templateUrl: './addblank.component.html',
  styleUrls: ['./addblank.component.scss']
})
export class AddblankComponent implements OnInit {
  constructor(public firebaseService: FirebaseService) { }
dentalmaterial;
rohlinge = [];

  ngOnInit() {
    this.getDentalmaterial();
  }

onSubmit() {
  this.firebaseService.blankform.value.rohlinge = this.rohlinge;
  const data = this.firebaseService.blankform.value;

 this.firebaseService.createRohlinge(data)
     .then(res => {
     });
}


getDentalmaterial() {
  this.firebaseService
  .getDentalmaterial()
  .subscribe(res => (this.dentalmaterial = res));
 }
}

