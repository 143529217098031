import {
  Component,
  OnInit
} from '@angular/core';
import {
  FirebaseService
} from '../shared/firebase.service';
import {
  AngularFirestore, AngularFirestoreCollection
} from '@angular/fire/firestore';
import { Observable } from 'rxjs';

interface Materialandcolor {
  material: string;
}

@Component({
  selector: 'app-scannedorders',
  templateUrl: './scannedorders.component.html',
  styleUrls: ['./scannedorders.component.scss']
})
export class ScannedordersComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
  ) {}

  isOpen = false;
  isOpen2 = false;
  isOpen4 = false;
  showInfo = true;
  modal;
  ModalID;
  ModalID2;
  showsecondblank = true;
  showsecondblanklater = false;
  submitbutton = false;
  files;
  files2;
  size;
  dentalmaterial;
  dentalrohling;
  dentalrohling2;
  dentalrohlingcharge;
  dentalrohlingcharge2;
  dentalrohlingcharge3;
  showQR;
  laterwork: boolean;
  optionF7: false;
  modalopen = false;
  detailpage;
  docID;
  note;
  lieferdatum: string = "11.12.2019";
  deleteava = true;
  negativdate2;

  detailpage_form;
  modalopen_second = false;

  detailpage_form_material;
  modalopen_third = false;;

  public options: string;
  public options2: string;

  dateok: boolean = true;
  datemessage: string = '';

  // Modal Form Edit

  modal_desc;
  modal_name;
  modal_lastname;
  modal_ordernumber;
  modal_3dprinting;
  modal_technican;

  // Material Edit form

  materialbrandcolorCol: AngularFirestoreCollection<Materialandcolor>;
  materialbrandcolor: Observable<Materialandcolor[]>;
  materialtypeCollection: AngularFirestoreCollection<Materialandcolor>;
  materiatype: Observable<Materialandcolor[]>;
  materialbrandCollection: AngularFirestoreCollection<Materialandcolor>;
  materialbrand: Observable<Materialandcolor[]>;

  modal_materiatype;
  modal_materialbrand;
  modal_materialbrandcolor;

  modal_material_disable = false;


  

  technicans;

  ngOnInit() {
    this.getUploads();
    this.getUploads2();
    this.getTotalNumber();
    this.getDentalrohling();
    this.firebaseService.NewOrderCount();
  }


  // Get Material for form


  getOptions() {
    this.getMaterialbrands();
    this.modal_materialbrandcolor = '';
    this.modal_materialbrand = '';
    if (this.modal_materialbrand == '') {
      this.modal_material_disable = false;
    } else {
      this.modal_material_disable = true;
    }
  }
  getOptions2() {
    this.getMaterialcolors();
    this.modal_materialbrandcolor = '';
    if (this.modal_materialbrand == '') {
      this.modal_material_disable = false;
    } else {
      this.modal_material_disable = true;
    }
  }

  getMaterialtypes() {
    this.materialtypeCollection = this.afs.collection('materialtypes');
    this.materiatype = this.materialtypeCollection.valueChanges();
  }

  getMaterialbrands() {

    const materialtype = this.modal_materiatype;

    this.materialbrandCollection = this.afs.collection('materialbrands', ref => {
      return ref.where('typebrandkey', '==', materialtype);
    });
    this.materialbrand = this.materialbrandCollection.valueChanges();
  }

  getMaterialcolors() {

    const materialbrand = this.modal_materialbrand;

    this.materialbrandcolorCol = this.afs.collection('materialcolors', ref => {
      return ref.where('brand', '==', materialbrand);
    });
    this.materialbrandcolor = this.materialbrandcolorCol.valueChanges();
  }

  OpenDetailpageMaterialForm(data) {
    console.log("Detail 3");
    this.getMaterialtypes();
    this.detailpage_form_material = data.payload.doc.data();
    this.docID = data.payload.doc.id;
    this.modalopen_third = true;
    this.modal_materiatype = data.payload.doc.data().materiatype;
    this.modal_materialbrand = data.payload.doc.data().materialbrand;
    this.modal_materialbrandcolor = data.payload.doc.data().teethcolor;
  }

  setChangesMaterial() {
    this.modalopen_third = false;
    console.log(this.docID);
      return this.afs
          .collection('files')
          .doc(this.docID)
          .set({ 
            teethcolor: this.modal_materialbrandcolor, 
            materialbrand: this.modal_materialbrand, 
            materialtype: this.modal_materiatype,
           }, { merge: true});
  }


  totaltime() {
    const ms = this.lieferdatum; // your input string
    const a = ms.split('.'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    const day = 100 - (+a[0]);
    const month = 100 - (+a[1]);
    const year = 10000 - (+a[2]);
    const d = day.toString()
    const m = month.toString()
    const y = year.toString()
    this.negativdate2 = (y + m + d)
    console.log(day);
    console.log(month);
    console.log(year);
    console.log(this.negativdate2);
  }

  datecheckMill() {
    console.log(this.lieferdatum)
    const ms = this.lieferdatum; // your input string
    const a = ms.split('.');
    const day = (+a[0]);
    const month = (+a[1]);
    const year = (+a[2]);
    const hour = 15;
    const minute = 0;
    const second = 0;
    const datum = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
    const newdate = datum.getTime();
    console.log(Date.now())
    console.log(newdate)
    if (newdate > Date.now()) {
      console.log('Termin ist OK!')
      this.dateok = false;
      this.setOrder();
    } else {
      console.log('Termin ist NICHT OK!')
      this.datemessage = 'Datum liegt in der Vergangeheit!';
    }
  }

  datecheckTray() {
    console.log(this.lieferdatum)
    const ms = this.lieferdatum; // your input string
    const a = ms.split('.');
    const day = (+a[0]);
    const month = (+a[1]);
    const year = (+a[2]);
    const hour = 15;
    const minute = 0;
    const second = 0;
    const datum = new Date(Date.UTC(year, month - 1, day, hour, minute, second));
    const newdate = datum.getTime();
    console.log(Date.now())
    console.log(newdate)
    if (newdate > Date.now()) {
      console.log('Termin ist OK!')
      this.dateok = false;
      this.setOrderTray();
    } else {
      console.log('Termin ist NICHT OK!')
      this.datemessage = 'Datum liegt in der Vergangeheit!';
    }
  }

  OpenDetailpage(data) {
    this.detailpage = data.payload.doc.data();
    this.docID = data.payload.doc.id;
    this.note = '';
    this.lieferdatum = '';
    this.modalopen = true;
    this.dateok = true;
    this.datemessage = '';
  }

  OpenDetailpageForm(data) {
    this.getTechnicans();
    this.detailpage_form = data.payload.doc.data();
    this.docID = data.payload.doc.id;
    this.modalopen_second = true;
    this.modal_desc = data.payload.doc.data().description;
    this.modal_name = data.payload.doc.data().patname;
    this.modal_lastname = data.payload.doc.data().patlastname;
    this.modal_ordernumber = data.payload.doc.data().ordernumber;
    this.modal_3dprinting = data.payload.doc.data().printing;
    this.modal_technican = data.payload.doc.data().technican;
  }

  setChanges() {
    this.modalopen_second = false;
    console.log(this.docID);
      return this.afs
          .collection('files')
          .doc(this.docID)
          .set({ 
            description: this.modal_desc, 
            patname: this.modal_name, 
            patlastname: this.modal_lastname,
            ordernumber: this.modal_ordernumber, 
            printing: this.modal_3dprinting,
            technican: this.modal_technican,
           }, { merge: true});
  }

  getTechnicans() {
    this.technicans = this.afs.collection('technicans', ref => {
    return ref.orderBy('tecnumber'); }).valueChanges();
   }

  openModal() {
    this.modalopen = true;
  }

  setOrder() {
    this.totaltime();
    this.modalopen = false;
    const date = `${Date.now()}`;
    return this.afs
      .collection('files')
      .doc(this.docID)
      .set({
        negativdate: this.negativdate2,
        status: 2,
        docID: this.docID,
        docID2: this.docID,
        deliverydate: this.lieferdatum,
        notes: this.note,
        date
      }, {
        merge: true
      });
  }

  setOrderTray() {
    this.totaltime();
    this.modalopen = false;
    const date = `${Date.now()}`;
    return this.afs
      .collection('files')
      .doc(this.docID)
      .set({
        negativdate: this.negativdate2,
        status: 11,
        docID: this.docID,
        docID2: this.docID,
        deliverydate: this.lieferdatum,
        notes: this.note,
        date
      }, {
        merge: true
      });
  }

  deleteUploads = data => this.firebaseService.deleteUploads(data);

  getUploads = () =>
    this.firebaseService.getDTUploads().subscribe(res => (this.files = res))

  getUploads2 = () =>
    this.firebaseService.getworkingUploads2().subscribe(res => (this.files2 = res))

  getTotalNumber = () =>
    this.firebaseService.WorkOrderCount().subscribe(snap => {
      this.size = snap.size;
      console.log(this.size);
    })

  getDentalmaterial() {
    this.firebaseService
      .getDentalmaterial()
      .subscribe(res => (this.dentalmaterial = res));
  }

  getDentalrohling() {
    this.firebaseService
      .getRohlinge()
      .subscribe(res => (this.dentalrohling = res));
  }

  getDentalrohlingcharge() {
    this.firebaseService
      .getChargenumber()
      .subscribe(res => (this.dentalrohlingcharge = res));
  }

  getDentalrohlingcharge2() {
    this.firebaseService
      .getChargenumber2()
      .subscribe(res => (this.dentalrohlingcharge2 = res));
  }

  getDentalrohlingcharge3() {
    this.firebaseService
      .getChargenumber3()
      .subscribe(res => (this.dentalrohlingcharge3 = res));
  }

  delete(data) {
    this.deleteUploads(data);
  }

  Submit(data) {
    this.setlaterwork();
    this.markCompleted(data);
    this.isOpen = false;
  }

  Submit3(data) {
    this.markCompleted2(data);
    this.isOpen4 = false;
  }

  Submit4(data) {
    this.markCompleted3(data);
    this.isOpen4 = false;
  }

  SubmitDelete(data) {
    this.deleteUploads(data);
    this.isOpen2 = false;
  }
  getready(data) {
    this.markCompletedDT(data);
    this.isOpen4 = false;
  }
  getready2(data) {
    this.markCompletedDTtray(data);
    this.isOpen4 = false;
  }

  markCompleted = data =>
    this.firebaseService.updateworkingOrderfinal(data)

  markCompletedDT = data =>
    this.firebaseService.getDTready(data)

  markCompletedDTtray = data =>
    this.firebaseService.getDTreadytray(data)

  setlaterwork() {
    this.firebaseService.updateworkingOrder();
  }

  markCompleted2 = data =>
    this.firebaseService.updateworkingOrder2(data)

  // laterwork on false
  markCompleted3 = data =>
    this.firebaseService.updateworkingOrder3(data)



  moveBack = data =>
    this.firebaseService.movetoNew(data)

  toggleDisplay() {
    this.showInfo = !this.showInfo;
  }
  toggleDisplay2() {
    this.showsecondblank = !this.showsecondblank;
    this.showsecondblanklater = !this.showsecondblanklater;
    this.submitbutton = !this.submitbutton;
  }

  DisplayModal1(data) {
    this.MatchDocID(data);
    this.isOpen = true;
  }
  DisplayModal2(data) {
    this.MatchDocID(data);
    this.isOpen2 = true;
  }
  DisplayModal4(data) {
    this.MatchDocID(data);
    this.isOpen4 = true;
  }

  MatchDocID(data) {
    this.ModalID = data.payload.doc.id;
  }

  MatchDocID2(data) {
    this.ModalID2 = data.payload.doc.id;
  }


}