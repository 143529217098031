import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AngularFirestoreCollection, AngularFirestore } from '@angular/fire/firestore';
import { Observable } from 'rxjs';

interface Materialandcolor {
  material: string;
}

@Component({
  selector: 'app-tray',
  templateUrl: './tray.component.html',
  styleUrls: ['./tray.component.scss']
})
export class TrayComponent implements OnInit {

  isOpen = false;

  negativdate2;

  formGroup: FormGroup;
  descFormControl: FormControl;
  materialFormControl: FormControl;
  farbeFormControl: FormControl;
  notesFormControl: FormControl;
  typeFormControl: FormControl;
  brandFormControl: FormControl;
  patnameFormControl: FormControl;
  patlastnameFormControl: FormControl;
  technicanFormControl: FormControl;

  public options: string;
  public options2: string;


  materialbrandcolorCol: AngularFirestoreCollection<Materialandcolor>;
  materialbrandcolor: Observable<Materialandcolor[]>;
  materialtypeCollection: AngularFirestoreCollection<Materialandcolor>;
  materiatype: Observable<Materialandcolor[]>;
  materialbrandCollection: AngularFirestoreCollection<Materialandcolor>;
  materialbrand: Observable<Materialandcolor[]>;

  technicans;

  constructor(
    private db: AngularFirestore,
    private formBuilder: FormBuilder,
  ) {
    this.descFormControl = new FormControl('', Validators.required);
    this.farbeFormControl = new FormControl('', Validators.required);
    this.notesFormControl = new FormControl('');
    this.typeFormControl = new FormControl('', Validators.required);
    this.brandFormControl = new FormControl('', Validators.required);
    this.patnameFormControl = new FormControl('', Validators.required);
    this.patlastnameFormControl = new FormControl('', Validators.required);
    this.technicanFormControl = new FormControl('', Validators.required);

    this.formGroup = this.formBuilder.group({
     desc: this.descFormControl,
     teethcolor: this.farbeFormControl,
     notes: this.notesFormControl,
     materialtype: this.typeFormControl,
     brand: this.brandFormControl,
     patname: this.patnameFormControl,
     patlastname: this.patlastnameFormControl,
     technican: this.technicanFormControl,
  });

  }

  ngOnInit() {
    this.getMaterialbrands();
    this.getTechnicans();
  }

  getMaterialtypes() {
    this.materialtypeCollection = this.db.collection('materialtypes');
    this.materiatype = this.materialtypeCollection.valueChanges();
  }

  getMaterialbrands() {

    const materialtype = '3D Druck';

    this.materialbrandCollection = this.db.collection('materialbrands', ref => {
      return ref.where('typebrandkey', '==', materialtype);
    });
    this.materialbrand = this.materialbrandCollection.valueChanges();
  }

  getMaterialcolors() {

    const materialbrand = (this.formGroup.get('brand').value);

    this.materialbrandcolorCol = this.db.collection('materialcolors', ref => {
      return ref.where('brand', '==', materialbrand);
    });
    this.materialbrandcolor = this.materialbrandcolorCol.valueChanges();
  }

  getOptions() {
    this.getMaterialbrands();
  }
  getOptions2() {
    this.getMaterialcolors();
  }

  getTechnicans() {
    this.technicans = this.db.collection('technicans', ref => {
    return ref.orderBy('tecnumber'); }).valueChanges();
   }

   
   totaltime() {
    const ms = this.formGroup.get('deliverydate').value;   // your input string
    const a = ms.split('.'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    const day = 100 -(+a[0]);
    const month = 100 - (+a[1]);
    const year = 10000 - (+a[2]);
    const d = day.toString()
    const m = month.toString()
    const y = year.toString()
    this.negativdate2 = ( y + m + d)
    console.log(day);
    console.log(month);
    console.log(year);
    console.log(this.negativdate2);
  }

  startUpload() {
      this.isOpen = true;
      const date = `${Date.now()}`;
      const ordernumber = 1;
      const whattosend = 'Datensatz';
      const description = this.formGroup.get('desc').value;
      const teethcolor = this.formGroup.get('teethcolor').value;
      const notes = this.formGroup.get('notes').value;
      const status = 0;
      const archiv = false;
      const printstatus = 0;
      const printing = true;
      const skipmilling = true;
      const companyname = 'Dental-Technik Hamm';
      const materialbrand = this.formGroup.get('brand').value;
      const materialtype = '3D Druck';
      const patname = this.formGroup.get('patname').value;
      const patlastname = this.formGroup.get('patlastname').value;
      const technican = this.formGroup.get('technican').value;
      

    this.db.collection('files').add( { companyname,
          date, whattosend, status, ordernumber, description, teethcolor, notes,
          materialbrand, materialtype, patname, patlastname, technican, archiv, printstatus, printing, skipmilling});
      }

}

