import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-printing',
  templateUrl: './printing.component.html',
  styleUrls: ['./printing.component.scss']
})
export class PrintingComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    ) { }

    files;
    printstate: boolean;

  ngOnInit() {
    this.getUploads();
  }


  getUploads = () =>
  this.firebaseService.getworkingPrinting().subscribe(res => (this.files = res))


    Submit(data) {
    this.setState1(data);
    }
    Submit2(data) {
    this.setState2(data);
    }
    Submit3(data) {
    this.setState3(data);
    }
    Submit4(data) {
    this.setState4(data);
    this.Finalprintstate(data);
    }
    Submit5(data) {
    this.setState5(data);
    this.Finalprintstate(data);
    }
    Submit6(data) {
    this.setState6(data);
    }

    Finalprintstate(data) {
    if (data.payload.doc.data().printstatus === 4) {
      this.printstate = true;
    } else {
      this.printstate = true;
    }
    }

    setState1 = data =>
    this.firebaseService.setprintstate(data)

    setState2 = data =>
    this.firebaseService.setprintstate2(data)

    setState3 = data =>
    this.firebaseService.setprintstate3(data)

    setState4 = data =>
    this.firebaseService.setprintstate4(data)

    setState5 = data =>
    this.firebaseService.setprintstate5(data)

    setState6 = data =>
    this.firebaseService.setprintstatefinal(data)
}
