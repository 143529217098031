import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
  ) { }

  files;

  ngOnInit() {
    this.getUploads();
  }

  getUploads = () =>
  this.firebaseService.getallUploads().subscribe(res => (this.files = res))
}
