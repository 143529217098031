import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-premilling',
  templateUrl: './premilling.component.html',
  styleUrls: ['./premilling.component.scss']
})
export class PremillingComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    ) { }

  isOpen = false;
  isOpen2 = false;
  showInfo = true;
  files;
  files2;
  size;
  dentalmaterial;
  dentalrohling;
  dentalrohlingcharge;

  public options: string;

  ngOnInit() {
    this.getUploads();
    this.getUploads2();
    this.getTotalNumber();
    this.getDentalrohling();
    this.firebaseService.NewOrderCount();
  }

  deleteUploads = data => this.firebaseService.deleteUploads(data);

  getUploads = () =>
  this.firebaseService.PrepareMillingUploads().subscribe(res => (this.files = res))

  getUploads2 = () =>
  this.firebaseService.PrepareMillingUploads2().subscribe(res => (this.files2 = res))

  getTotalNumber = () =>
  this.firebaseService.PrepareMillingCount().subscribe(snap => {
    this.size = snap.size;
    console.log(this.size);
  })

  getDentalmaterial() {
    this.firebaseService
    .getDentalmaterial()
    .subscribe(res => (this.dentalmaterial = res));
   }

   getDentalrohling() {
    this.firebaseService
    .getRohlinge()
    .subscribe(res => (this.dentalrohling = res));
   }

   getDentalrohlingcharge() {
    this.firebaseService
    .getChargenumber()
    .subscribe(res => (this.dentalrohlingcharge = res));
   }


  Submit(data) {
    this.markCompleted(data);
    this.isOpen = false;
  }

  Submit2(data) {
    this.markCompleted2(data);
    this.isOpen = false;
  }

  SubmitDelete(data) {
    this.deleteUploads(data);
    this.isOpen2 = false;
  }


  markCompleted = data =>
    this.firebaseService.prepareforMilling(data)

    markCompleted2 = data =>
    this.firebaseService.prepareforMilling2(data)

    moveBack = data =>
    this.firebaseService.movetoNew(data)

    toggleDisplay() {
      this.showInfo = !this.showInfo;
    }
    getOptions() {
      this.getDentalrohlingcharge();
    }

}


