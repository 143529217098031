import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';


@Component({
  selector: 'app-kunden-list',
  templateUrl: './kunden-list.component.html',
  styleUrls: ['./kunden-list.component.scss']
})
export class KundenListComponent implements OnInit {

  constructor(private firebaseService: FirebaseService) { }

  emailVerified = true;

  users;

  ngOnInit() {
    this.getUsers();
  }

  deleteUsers = data => this.firebaseService.deleteUsers(data);

  getUsers = () =>
  this.firebaseService
  .getUsers()
  .subscribe(res => (this.users = res))
}





